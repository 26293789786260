import React from 'react'

import Layout from '@components/layout'
import { getStaticPage, modules, pageSeo } from '@lib/api'

import { Module } from '@modules/index'

const ErrorPage = ({ data }) => {
  const { site, menus, page } = data

  return (
    <Layout
      site={site}
      menus={menus}
      page={{
        seo: page?.seo,
      }}
    >
      {page.modules?.map((module, key) => (
        <Module key={key} uniqueId={key} module={module} />
      ))}
    </Layout>
  )
}

export async function getStaticProps({ preview, previewData, locale }) {
  const pageData = await getStaticPage({
    pageData: `
    *[_type == "errorPage"] | order(_updatedAt desc)[0]{
      modules[]{
        ${modules(locale)}
      },
      "seo": {
        ${pageSeo(locale)}
      }
    }
  `,
    preview: {
      active: preview,
      token: previewData?.token,
    },
    locale,
  })

  return {
    props: {
      data: pageData,
    },
    revalidate: 60,
  }
}

export default ErrorPage
